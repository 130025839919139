import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Footer } from "../components/core/Footer";
import { Header } from "../components/core/Header";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import ErgotherapiePage from "../pages/ErgotherapiePage";
import FAQsPage from "../pages/FAQsPage";
import NeurologieGariatriePage from "../pages/NeurologieGariatriePage";
import PediatricsPage from "../pages/PediatricsPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import PsychatriePage from "../pages/PsychatriePage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import UnknownPage from "../pages/UnknownPage";
import {
  PATH_ABOUT,
  PATH_ADDITIONAL_OFFERS,
  PATH_CONTACT,
  PATH_ERGOTHERAPIE,
  PATH_FAQ as PATH_FAQS,
  PATH_NEUROLOGIE_GERIATRIE,
  PATH_PEDIATRICS,
  PATH_PRIVACY_POLICY,
  PATH_PSYCHATRIE,
  PATH_START,
  PATH_TERMS_AND_CONDITIONS,
  PATH_UNKNOWN,
} from "../utils/constants";
import OfficeLocationComponent from "../components/common/OfficeLocationComponent";
import StartPage from "../pages/StartPage";
import OtherOffersPage from "../pages/OtherOffersPage";

const ContentContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="pt-20 md:pt-28 lg:pt-36 min-h-screen">
    <div className="">
      <main className="isolate">
        <div className="relative isolate -z-10 overflow-hidden">
          <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg]  shadow-xl shadow-ergo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
          />
          <div className="mx-auto max-w-6xl px-6 lg:px-8">{children}</div>
        </div>
      </main>
    </div>
  </div>
);

export const PublicApp = () => {
  return (
    <>
      <Header />
      <ContentContainer>
        <Routes>
          <Route path={PATH_START} element={<StartPage />} />
          <Route path={PATH_ERGOTHERAPIE} element={<ErgotherapiePage />} />
          <Route path={PATH_NEUROLOGIE_GERIATRIE} element={<NeurologieGariatriePage />} />
          <Route path={PATH_PSYCHATRIE} element={<PsychatriePage />} />
          <Route path={PATH_PEDIATRICS} element={<PediatricsPage />} />
          <Route path={PATH_ADDITIONAL_OFFERS} element={<OtherOffersPage />} />
          <Route path={PATH_ABOUT} element={<AboutPage />} />
          <Route path={PATH_CONTACT} element={<ContactPage />} /> <Route path={PATH_FAQS} element={<FAQsPage />} />
          <Route path={PATH_TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
          <Route path={PATH_PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={PATH_UNKNOWN} element={<UnknownPage />} />
        </Routes>
        <OfficeLocationComponent />
      </ContentContainer>
      <Footer />
    </>
  );
};
