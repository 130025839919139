import { CalendarIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";

import { calculateYears, classNames } from "../utils/utils";
import { useHref, useNavigate } from "react-router-dom";
import OfficeLocationComponent from "../components/common/OfficeLocationComponent";

interface Training {
  title: string;
  location?: string;
  date: string;
}

interface TrainingCategory {
  categoryName: string | undefined;
  trainings: Training[];
}

interface Highlight {
  title: string;
  location?: string;
  date?: string;
  ratio: string;
  image?: string;
  href?: string;
}

const highlights: Highlight[] = [
  {
    title:
      "Autorin der Broschüre: Liebe, Lust und Leidenschaft, Sexualität nach Schlaganfall (für die Stiftung der Deutschen Schlaganfall-Hilfe)",
    location: "Gütersloh",
    date: "2020",
    ratio: "aspect-[4/5]",
    image: process.env.PUBLIC_URL + "/Sexualität_nach_Schlaganfall_Titel.jpg",
  },
  // {
  //   title:
  //     "Ausbildung zur Sexualberaterin in klinischer Sexologie nach Sexocorporel",
  //   location: "Kißlegg",
  //   date: "2017 - 2019",
  //   ratio: "aspect-[4/5]",
  // },
];

const trainingsMeyer: TrainingCategory[] = [
  {
    categoryName: undefined,
    trainings: [
      {
        title: "IPT Kompaktkurs",
        location: "Freiburg",
        date: "20.06-22.06.24",
      },
      {
        title: "Energiemanagement-Schulung für Menschen mit Fatigue (EMS)",
        location: "Valens",
        date: "",
      },
      {
        title: "Post Covid im ambulanten Setting",
        date: "02.11.24",
      },
      {
        title:
          "Rottweiler Herbstsymposium für Psychiatrie, Psychotherapie und Psychosomatik im Vinzenz von Paul Hospital",
        location: "Rottweil",
        date: "09.11.14",
      },
      {
        title: "Neurotraining nach Verena Schweizer",
        location: "Zürich",
        date: "18.11/19.11.24",
      },
      {
        title: "Hilfsmittelversorgung",
        location: "Albstadt",
        date: "05.12.24",
      },
    ],
  },
];

const trainingsFroehlich: TrainingCategory[] = [
  {
    categoryName: "Handtherapie",
    trainings: [
      {
        title: "Evidenzbasierte Hand- und Armbehandlung in der Neurologie",
        date: "15.07.24",
      },
      {
        title: "CRPS (Mb.Sudeck) ganzheitlich behandeln",
        date: "10.07.24",
      },
      {
        title: "Narbentherapie",
        date: "Juli 2024",
      },
    ],
  },
  {
    categoryName: "Pädiatrie",
    trainings: [
      {
        title: "Spielideen zur Förderung von Körperbewusstsein und Wahrnehmung",
        date: "10.04.24",
      },
      {
        title: "Den Selbstwert von Kindern und Jugendlichen stärken",
        date: "11.04.24",
      },
      {
        title: "Weiterbildung zum Coach für psychische Gesundheit für Kinder und Jugendliche",
        date: "21.10-24.10.24",
      },
    ],
  },
];

const trainings: TrainingCategory[] = [
  {
    categoryName: "Neurologie/Geriatrie",
    trainings: [
      {
        title: "Energiemanagement-Schulung für Menschen mit Fatigue (EMS)",
        location: "Karlsruhe",
        date: "05.07-06.07.2024",
      },
      {
        title: "Hospizliche Begleitung und palliative Pflege sterbenskranker Menschen",
        location: "Rottweil",
        date: "22.02.2017",
      },
      {
        title: "Pflege nach Bobath-Lagern und positionieren im Bett",
        location: "Rottweil",
        date: "15.03.2016",
      },
      {
        title: "Einführung in die F.O.T.T",
        location: "Burgau",
        date: "03.03-04.03.2016",
      },
      {
        title: "Special Edition Neurologie",
        location: "Bochum",
        date: "06.-07.11.2015",
      },
      {
        title: "LIN- Lagerung in Neutralnullstellung",
        location: "Burgau",
        date: "16.-18.10.2015",
      },
      {
        title: "Befundung und Behandlung von Hemiplegiepatienten mit schmerzhafter Schulter",
        location: "Reutlingen",
        date: "17.07-18.07.2015",
      },
      {
        title:
          "Rottweiler Herbstsymposium für Psychiatrie, Psychotherapie und Psychosomatik im Vinzenz von Paul Hospital",
        location: "Rottweil",
        date: "09.11.2014",
      },
      { title: "Gelenksymposium", location: "Leipzig", date: "08.03.2014" },
      {
        title: "Teilhaben- (neuro)logisch!",
        location: "Stuttgart",
        date: "26.01.2014",
      },
      {
        title: "Hemiparese: Behandlung der oberen Extremität",
        location: "Reutlingen",
        date: "30.11 - 02.12. 2013",
      },
      {
        title: "Bobath- 24- Stunden-Konzept",
        location: "Reutlingen",
        date: "28.11-30.11.2013",
      },
      {
        title: "Bobath Workshop Multiple Sklerose",
        location: "Kassel",
        date: "11.11-15.11.2013",
      },
      {
        title: "HoDT (Handlungsorientierte Diagnostik und Therapie)",
        location: "Berlin",
        date: "13.-19.10.2014",
      },
      {
        title: "Aufgabenorientiertes Training: posturale Kontrolle",
        location: "Allensbach",
        date: "19.10.2013",
      },
    ],
  },
  {
    categoryName: "Psychiatrie",
    trainings: [
      {
        title: "„Curriculum zur Interpersonellen Psychotherapie (IPT) - Kompaktkurs: Einführung und Aufbau“",
        location: "Freiburg",
        date: "27.04-29.04.2017",
      },
      {
        title:
          "Psychotherapie Fernlehrgang zur Vorbereitung auf die amtsärztliche Prüfung nach dem Heilpraktikergesetz",
        location: "Institut für Lernsysteme",
        date: "14.03.2016-14.06.2017",
      },
      {
        title: "Ergotherapie von Menschen mit Schizophrenie und wahnhaften Störungen",
        location: "Marburg",
        date: "12.-13.12.2014",
      },
      {
        title: "25.Rottweiler Herbstsymposium",
        location: "Rottweil",
        date: "12.11.2016",
      },
      { title: "Delir", location: "Rottweil", date: "12.10.2016" },
      {
        title: "Deeskalationstraining",
        location: "Rottweil",
        date: "14./15./28./29.09.2015",
      },
    ],
  },
  {
    categoryName: "Pädiatrie",
    trainings: [
      {
        title: "Basiswissen Autismus",
        date: "19.10.2024",
      },
      {
        title: "Spielideen zur Förderung von Körperbewusstsein und Wahrnehmung",
        date: "10.04.2024",
      },
      {
        title: "Kindernotfalltraining",
        location: "Steinenbronn",
        date: "06.09.2019",
      },
      {
        title: "TLS in der therapeutischen Praxis",
        location: "Bellheim",
        date: "11.06-15.06.2014",
      },
      {
        title: "Präventive Ergotherapie an Grundschulen",
        location: "Mannheim",
        date: "14.09.2013",
      },
    ],
  },
  {
    categoryName: "Sexualität",
    trainings: [
      {
        title: "Zwei jährige Weiterbildung: Sexualtherapie in klinischer Sexologie nach Sexocorporel",
        location: "Kißlegg",
        date: "2017- 2019",
      },
      {
        title: "Multiplikatoren Schulung zum Thema sexualisierte Gewalt in Einrichtungen",
        location: "Untermachtal",
        date: "2022",
      },
    ],
  },
  {
    categoryName: "Zusatzqualifikationen",
    trainings: [
      {
        title: "Betätigungsorientierte Ergotherapie",
        location: "Stuttgart",
        date: "21.03.2015",
      },
      {
        title: "Besprechungen in Teams -Ergebnisorientiert Führen",
        location: "Rottweil",
        date: "20.02.2017",
      },
      {
        title: "Einführung in das „Ergotherapeutische Assessment“",
        location: "Karlsbad-Langensteinbach",
        date: "14.-16.03.2011",
      },
    ],
  },
];

export default function AboutPage() {
  const TrainingComponent: React.FC<Training> = ({ title, location, date }) => (
    <div className="transition-all duration-900 ease-in-out rounded-2xl shadow-sm bg-ergo-50 p-4">
      <h3 className="text-md leading-6 font-semibold text-gray-900">{title}</h3>
      <div className="mt-3 space-x-1 flex">
        {location && (
          <div className="flex items-center text-xs text-gray-700">
            <p className="leading-4">{location},</p>
          </div>
        )}
        <div className="flex items-center text-xs text-gray-700">
          <p className="leading-4">{date}</p>
        </div>
      </div>
    </div>
  );

  const TrainingCategoryComponent: React.FC<TrainingCategory> = ({ categoryName, trainings }) => (
    <div className="pb-12">
      <h2 className="text-2xl font-bold leading-6 mb-6 text-gray-800">{categoryName}</h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {trainings.map((training, index) => (
          <TrainingComponent key={index} {...training} />
        ))}
      </div>
    </div>
  );

  const HighlightComponent: React.FC<Highlight> = ({ title, location, date, ratio, image, href }) => (
    <div
      className={`transition-all duration-900 ease-in-out rounded-2xl shadow-sm bg-ergo-50 max-w-xs aspect-[${ratio}]`}
      onClick={() => {
        if (href) window.open(href, "_blank");
      }}
    >
      {image && <img className="w-full h-auto rounded-lg" src={image} alt={title} />}
      <div className="px-4 pt-4">
        <h3 className="text-sm leading-6 font-semibold text-gray-800 mb-2">{title}</h3>
        <div className="mb-4 space-x-1 flex text-xs text-gray-700">
          {location && <p className="leading-4">{location},</p>}
          <p className="leading-4">{date}</p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Unser Team - Lisa Spreitzer, Charlotte Fröhlich, Sandra Meyer</title>
        <meta
          name="description"
          content="Lisa Spreitzer ist eine professionelle Ergotherapeutin mit jahrelanger Erfahrung in den Bereichen Neurologie, Geriatrie, Psychiatrie und Pädiatrie. Ihre Therapie ist auf den Alltag ihrer Patienten ausgerichtet."
        />
        <meta
          name="keywords"
          content="Ergotherapeutin, Lisa Spreitzer, Neurologie, Geriatrie, Psychiatrie, Pädiatrie, Rottweil, RW"
        />
      </Helmet>
      <div className="items-center mx-auto max-w-5xl">
        <div className="max-w-4xl">
          <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Unser Team</h1>
          <p className="mt-6 text-md leading-7 text-gray-600">
            Unser Team bei Ergotherapie Rottweil besteht aus erfahrenen Ergotherapeutinnen, die sich leidenschaftlich
            für die ganzheitliche Betreuung ihrer Klienten einsetzen und durch ihre individuellen Stärken und
            Fachgebiete eine umfassende Versorgung gewährleisten.
          </p>
          <div className="mt-8 grid grid-cols-2 sm:grid-cols-4 gap-12 items-center">
            <div className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/images/uns.jpeg"}
                alt=""
                className="aspect-[4/4] w-full max-w-xs rounded-xl object-cover"
              />
              <h2 className="mt-4 font-medium text-md text-gray-600">Team</h2>
            </div>
            <div className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/images/lisa.png"}
                alt=""
                className="aspect-[4/4] w-full max-w-xs rounded-xl object-cover brightness-125"
              />
              <h2 className="mt-4 font-medium text-md text-gray-600">Lisa Spreitzer</h2>
            </div>
            <div className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/images/sandra_meyer.jpeg"}
                alt=""
                className="aspect-[4/4] w-full max-w-xs rounded-xl object-cover"
              />
              <h2 className="mt-4 font-medium text-md text-gray-600">Sandra Meyer</h2>
            </div>

            <div className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/images/charlotte_froehlich.jpeg"}
                alt=""
                className="aspect-[4/4] w-full max-w-xs rounded-xl object-cover"
              />
              <h2 className="mt-4 font-medium text-md text-gray-600">Charlotte Fröhlich</h2>
            </div>
          </div>
        </div>
        <div className="mt-16 lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
          <div className="max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Lisa Spreitzer</h1>
            <p className="mt-6 text-md leading-7 text-gray-600">
              Mein Name ist Lisa Spreitzer, ich bin verheiratet und Mutter von zwei Kindern. Seit{" "}
              <span className="font-semibold">{calculateYears("04/01/2013")} Jahren</span> arbeite ich leidenschaftlich
              als Ergotherapeutin, wobei mir besonders der Umgang mit Menschen jeden Alters Freude bereitet. In meiner
              langjährigen Praxis habe ich vor allem mit neurologischen, geriatrischen und psychiatrischen Klienten
              gearbeitet. Dabei ist es mir wichtig, dass die Therapie praxisnah ist und sich am Alltag orientiert.{" "}
              <br />
              <br />
              Gemeinsam entwickeln wir Strategien, um Ihnen zu helfen, Ihren Alltag besser zu bewältigen. Auch bei
              Kindern und Jugendlichen ist es entscheidend, frühzeitig an problematischen Bereichen zu arbeiten, um
              psychische Auffälligkeiten zu vermeiden.
            </p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "/images/lisa.png"}
            alt=""
            className="brightness-125 aspect-[3/4] w-full max-w-xs rounded-xl object-cover lg:max-w-xs xl:row-span-2 xl:row-end-2 m-8"
          />
        </div>
        <div className="mt-8 items-center mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-2 xl:grid-rows-1 xl:gap-x-8"></div>
        <div className="mt-16 container w-full space-y-12">
          {trainings.map((category, index) => (
            <TrainingCategoryComponent key={index} {...category} />
          ))}
        </div>
        <a className="inline-flex mt-16" href="https://www.sx-med.de" target="_blank" rel="noopener noreferrer">
          <div className="flex transition-all duration-900 bg-ergo-500/5 py-2.5 px-6 rounded-2xl shadow-sm bg-ergo-50 items-center">
            <p className="font-normal text-md text-gray-800">Mehr zu Sexualität auf &nbsp;</p>
            <span className="font-normal text-md text-ergo-500">sx-med.de</span>
          </div>
        </a>
        <div className="mt-12 container w-full space-y-12 mb-2">
          <div className="space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
            {highlights.map((highlight, index) => (
              <HighlightComponent key={index} {...highlight} />
            ))}
          </div>
        </div>
        <div>
          {/* Sandra Meyer Introduction */}
          <div className="mt-16 lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <div className="max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Sandra Meyer</h1>
              <p className="mt-6 text-md leading-7 text-gray-600">
                Als Ergotherapeutin ist es mein Ziel, traditionelle Ansätze zu überwinden und Therapien gemäß den
                neuesten Erkenntnissen und wissenschaftlichen Studien durchzuführen. Ich lege großen Wert darauf, mit
                meinen Klientinnen und Klienten ganzheitlich und praxisnah zu arbeiten, insbesondere in den Bereichen
                Psychiatrie und Neurologie. <br />
                <br />
                Während eines dreimonatigen Praktikums in den Kliniken Valens, Schweiz, konnte ich die beeindruckenden
                Ergebnisse des Energiemanagements kennenlernen. Ab sofort biete ich in unserer Praxis in Kleingruppen
                mit maximal fünf Teilnehmerinnen und Teilnehmern Schulungen zum Energiemanagement an. Diese
                Gruppenarbeit bietet eine hervorragende Möglichkeit für Personen, die unter ständiger Müdigkeit und
                Erschöpfung leiden und sich dauerhaft ausgelaugt und kraftlos fühlen, ihre Lebensqualität zu verbessern.
              </p>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/images/sandra_meyer.jpeg"}
              alt=""
              className="aspect-[3/4] w-full max-w-xs rounded-xl object-cover lg:max-w-xs xl:row-span-2 xl:row-end-2 m-8"
            />
          </div>
          <div className="mt-8 container w-full space-y-12">
            {trainingsMeyer.map((category, index) => (
              <TrainingCategoryComponent key={index} {...category} />
            ))}
          </div>

          {/* Charlotte Fröhlich */}
          <div className="mt-16 lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <div className="max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Charlotte Fröhlich
              </h1>
              <p className="mt-6 text-md leading-7 text-gray-600">
                Ich sehe die vielfältigen Möglichkeiten der Ergotherapie, Menschen ganzheitlich zu behandeln, ihre
                Stärken zu fördern und gemeinsam schwierige Situationen anzugehen. Meine Fachgebiete liegen insbesondere
                in den Bereichen Psychiatrie und Pädiatrie. <br />
                <br />
                Ich leite sowohl Kinder- als auch Elterngruppen, um Transparenz, Anleitung und ganzheitliche
                Unterstützung zu bieten. Ein Schwerpunkt meiner Arbeit ist das THOP-Kindertraining und
                THOP-Elterntraining, spezialisiert auf Kinder mit hyperkinetischem und oppositionellem Verhalten. Mir
                liegt es am Herzen, Kinder frühzeitig in ihrem Selbstwertgefühl und Selbstbewusstsein zu stärken und
                ihnen zu vermitteln, dass sie für sich selbst eintreten dürfen. Durch meine eigene Erfahrung im
                Kickboxen in meiner Jugend biete ich einen Selbstwert-Kurs für Kinder im Alter von 5 bis 8 Jahren an.
              </p>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/images/charlotte_froehlich.jpeg"}
              alt=""
              className="aspect-[3/4] w-full max-w-xs rounded-xl object-cover lg:max-w-xs xl:row-span-2 xl:row-end-2 m-8"
            />
          </div>
          <div className="mt-8 container w-full space-y-12">
            {trainingsFroehlich.map((category, index) => (
              <TrainingCategoryComponent key={index} {...category} />
            ))}
          </div>
        </div>{" "}
      </div>

      <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-ergo-50/30 sm:h-32" />
    </>
  );
}
