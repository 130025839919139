import React from "react";

import { PATH_ABOUT, footerLinkItems } from "../../utils/constants";

export const Footer = () => {
  const getTimeSpan = () => {
    const currentYear = new Date().getFullYear();
    return currentYear === 2023
      ? currentYear.toString()
      : `2023 - ${currentYear}`;
  };

  return (
    <footer>
      <div className="mt-32 mx-auto px-6 py-8 items-center">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-700">
            &copy;{getTimeSpan()}{" "}
            <span className="mx-1">Ergotherapie Rottweil</span>
            {" • "}{" "}
            <a href={PATH_ABOUT} className="mx-1 text-ergo-600">
              Lisa Spreitzer
            </a>
          </p>
          <div className="mt-4">
            <nav aria-label="Footer Navigation">
              <ul className="flex justify-center gap-4 text-xs">
                {footerLinkItems.map((item) => (
                  <li>
                    <a
                      href={item.href}
                      className="text-gray-500 transition text-gray-500 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};
