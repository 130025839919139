import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, MouseEventHandler, useState } from "react";
import React from "react";
import { LinkProps, useLocation } from "react-router-dom";

import { PATH_CONTACT, navigationItems } from "../../utils/constants";
import { classNames, isPath } from "../../utils/utils";
import { LogoSvg } from "../svgs/LogoSvg";

export const Header = () => {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  /**
   * Handle scroll event.
   */
  React.useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.pageYOffset > 80);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const borderClass = scrolled ? "border-b border-gray-100" : "";
  const shadowClass = scrolled ? "shadow-sm" : "";

  return (
    <header>
      <div
        className={`transition-all ease-in-out duration-300 fixed top-0 left-0 right-0 z-10 bg-[#fcfcf9] items-center ${borderClass} ${shadowClass}`}
      >
        <nav
          className={`max-w-6xl flex items-center justify-between py-3 px-8 lg:px-8 mx-auto`}
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Ergotherapie RW</span>
              <LogoSvg classNames="h-12 w-auto fill-current text-ergo-400" />
            </a>
          </div>
          <div className="flex lg:hidden ">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigationItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={classNames(
                  isPath(item.href, location.pathname)
                    ? "text-sm font-normal leading-6 text-ergo-500"
                    : "text-sm font-normal leading-6 text-gray-500"
                )}
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href={PATH_CONTACT}>
              <button
                type="button"
                className="rounded-md bg-ergo-100 px-2.5 py-1.5 text-sm font-normal text-ergo-700 hover:bg-ergo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Kontakt <span aria-hidden="true">&rarr;</span>
              </button>
            </a>
          </div>
        </nav>
      </div>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Ergotherapie Rottweil</span>
              <LogoSvg classNames="h-12 w-auto fill-current text-ergo-400" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigationItems.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="pt-8">
                <a href={PATH_CONTACT}>
                  <button
                    type="button"
                    className="rounded-md bg-ergo-100 px-2.5 py-1.5 text-sm font-normal text-ergo-700 hover:bg-ergo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Kontakt <span aria-hidden="true">&rarr;</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
