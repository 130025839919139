import React from "react";
import {
  Testimonial,
  TestimonialsComponent,
} from "../components/common/testimonial/TestiomonialComponent";
import { classNames } from "../utils/utils";
import { Helmet } from "react-helmet-async";

// TypeScript data structure
type Section = {
  title: string;
  content: string[];
  img?: string;
  ratio?: string;
};

const data: { sections: Section[]; testimonials: Testimonial[] } = {
  sections: [
    {
      title: "Pädiatrie",
      content: [
        "Aufmerksamkeit- und Konzentrationsstörungen",
        "ADS,ADHS",
        "Verhaltensschwierigkeiten z.B. sozialer Rückzug, aggressives Verhalten",
        "Schwierigkeiten mit dem Selbstbewusstsein/Selbstwert",
        "Ängste",
        "Schwierigkeiten im schulischen Bereich",
        "Konfliktsituationen zu Hause oder/und in der Schule",
      ],
      img: process.env.PUBLIC_URL + "/images/paediatrics.jpg",
      ratio: "aspect-[3/4]",
    },
    {
      title: "Was könnte eine Behandlung beinhalten?",
      content: [
        "Erstellen von Tages-/Wochenplänen",
        "Aufbau des Selbstbewusstseins/Selbstvertrauens",
        "Kommunikationstraining",
        "Soziales Kompetenztraining",
        "Erarbeiten von Interessen/Hobbys",
        "Verbesserung der Selbst- und Fremdwahrnehmung",
        "Verbesserung der Körperwahrnehmung",
        "Stressmanagement",
        "Verbesserung der Motivation",
        "Verbesserung der sozio-emotionalen Fähigkeiten",
        "Verbesserung von kognitiven Funktionen (z.B. Konzentrations- und Aufmerksamkeitstraining)",
        "Umgang mit Konfliktsituationen erlernen",
        "Umgang mit Ängsten",
      ],
    },
  ],
  testimonials: [
    {
      quote:
        "Meine Tochter hat im Verlauf der Ergotherapie gelernt, sich Ängsten zu stellen. Sie ist heute in der Lage alleine einkaufen zu gehen, ohne in Panik aus dem Laden zu rennen.",
    },
    {
      quote:
        "Durch die Ergotherapie habe ich viele wertvolle Tipps erhalten, um die Hausaufgaben mit meinem Sohn besser bewältigen zu können.",
    },
  ],
};

export default function PediatricsPage() {
  return (
    <>
      <Helmet>
        <title>Pädiatrie | Ergotherapie</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über die ergotherapeutischen Behandlungen im Bereich der Pädiatrie. Unsere Therapien helfen Kindern mit Aufmerksamkeits- und Konzentrationsstörungen, ADS/ADHS, Verhaltensschwierigkeiten, Ängsten und Schwierigkeiten im schulischen Bereich. Finden Sie heraus, wie wir dabei helfen, Selbstbewusstsein aufzubauen, Kommunikations- und soziale Kompetenzen zu verbessern und Kindern dabei zu helfen, ihre Ängste zu bewältigen."
        />
      </Helmet>
      <div>
        {data.sections.map((section, index) => (
          <div className="items-center mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <div className="lg:mt-0 xl:col-end-1 xl:row-start-1">
              <div key={index} className="p-6 ">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                  {section.title}
                </h2>
                <ul className="list-disc marker:text-ergo-300 list-inside space-y-1">
                  {section.content.map((item, index) => (
                    <li key={index} className="text-md leading-7 text-gray-600">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {section.img && (
              <img
                src={section.img}
                alt=""
                className={classNames(
                  "w-full max-w-xs rounded-3xl object-cover lg:max-w-xs xl:row-span-2 xl:row-end-2 m-4 shadow-md",
                  section.ratio || ""
                )}
              />
            )}
          </div>
        ))}
        <TestimonialsComponent testimonials={data.testimonials} />
      </div>
    </>
  );
}
