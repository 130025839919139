import React from "react";
import {
  Testimonial,
  TestimonialsComponent,
} from "../components/common/testimonial/TestiomonialComponent";
import { classNames } from "../utils/utils";
import { Helmet } from "react-helmet-async";

// TypeScript data structure
type Section = {
  title: string;
  text?: string;
  content?: string[];
  img?: string;
  ratio?: string;
};

const data: { sections: Section[]; testimonials: Testimonial[] } = {
  sections: [
    {
      title: "Psychiatrie",
      text: "Eine psychische Erkrankung kann jeden treffen. Nicht nur Erwachsene, bereits Kinder können psychische Auffälligkeiten aufweisen. Umso wichtiger ist es, diese ernst zu nehmen und entsprechend zu behandeln. Oft ist nicht nur der Leidensdruck groß, sondern auch die Scham. Die Praxis bietet den nötigen Raum, offen über bestehende Probleme zu sprechen. Auf Wünsche und Bedürfnisse des Klienten:In einzugehen. Innerhalb der Therapie werden gemeinsam Strategien und Lösungen erarbeitet, die sich am Alltag des Klienten:In orientieren.",
      img: process.env.PUBLIC_URL + "/images/psychiatry_1.jpg",
      ratio: "aspect-[3/4]",
    },
    {
      title: "Psychische Erkrankungen sind unter anderem",
      content: [
        "Depressionen",
        "Burnout",
        "Angst- und Panikstörungen",
        "Zwangsstörungen",
        "Schizophrenie",
        "Emotionale Störungen",
        "Psychische Verhaltensstörungen",
        "Persönlichkeitsstörungen",
        "Organisch bedingte psychische Störungen",
        "Belastungs- und somatoforme Störungen",
      ],
    },
    {
      title:
        "Eine psychisch-funktionelle Behandlung kann folgendes beinhalten:",
      content: [
        "Erarbeiten einer Tagesstrukturierung",
        "Erstellen von Tages-/Wochenplänen",
        "Aufbau des Selbstbewusstseins/Selbstvertrauens",
        "Kommunikationstraining",
        "Soziales Kompetenztraining",
        "Erarbeiten von Interessen/Hobbys",
        "Wiederaufnahme von Alltagstätigkeiten",
        "Verbesserung der Selbst- und Fremdwahrnehmung",
        "Verbesserung der Körperwahrnehmung",
        "Stressmanagement",
        "Verbesserung des Antriebs",
        "Verbesserung der Ausdauer und Belastbarkeit",
        "Verbesserung der Motivation",
        "Verbesserung der sozio-emotionalen Fähigkeiten",
        "Verbesserung von kognitiven Funktionen (z.B. Aufmerksamkeits- und Gedächtnistraining)",
        "Management für Selbstfürsorge",
        "Umgang mit Konfliktsituationen erlernen",
        "Genusstraining",
        "Umgang innerhalb der Partnerschaft",
        "Umgang mit dem Thema Sexualität",
        "Einbindung von Angehörigen/Bezugspersonen",
      ],
      img: process.env.PUBLIC_URL + "/images/psychiatry_2.jpg",
      ratio: "aspect-[3/4]",
    },
  ],
  testimonials: [
    {
      quote:
        "Früher konnte ich mich an vielem erfreuen, war im Verein sehr eingebunden und hatte einen großen Freundeskreis. Doch dann kam die Depression. Ich konnte mich immer schlechter konzentrieren, habe mich mehr und mehr zurückgezogen. Konnte morgens nicht mehr aufstehen, wollte nur noch im Bett liegen. Alles war anstrengend.",
    },
    {
      quote:
        "In der Ergotherapie habe ich gelernt, wieder die nötige Tagesstruktur zu erhalten. Ich kann nun morgens wieder aufstehen und bereits einige Alltagsverrichtungen durchführen. Es liegt noch viel Arbeit vor mir, aber ich sehe wieder Licht am Ende des Tunnels. Alleine hätte ich das nicht geschafft.",
    },
  ],
};

export default function PsychatriePage() {
  return (
    <>
      <Helmet>
        <title>Psychiatrie | Ergotherapie</title>
        <meta
          name="description"
          content="Psychische Erkrankungen wie Depressionen, Burnout, Angst- und Panikstörungen, Zwangsstörungen und Schizophrenie können Menschen in jedem Alter betreffen. Unsere ergotherapeutische Praxis bietet einen sicheren Raum, in dem offen über bestehende Probleme gesprochen und individuelle Strategien entwickelt werden können, um den Alltag besser zu bewältigen. Erfahren Sie mehr über unsere Behandlungsmethoden, einschließlich dem Aufbau von Selbstvertrauen, Kommunikationstraining, Stressmanagement und der Verbesserung kognitiver Funktionen."
        />
      </Helmet>
      <div>
        {data.sections.map((section, index) => (
          <div>
            <div className="items-center mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <div className="lg:mt-0 xl:col-end-1 xl:row-start-1">
                <div>
                  <div key={index} className="p-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6">
                      {section.title}
                    </h2>

                    <ul className="list-disc marker:text-ergo-300 list-inside space-y-1">
                      {section.content &&
                        section.content.map((item, index) => (
                          <li
                            key={index}
                            className="text-md leading-7 text-gray-600"
                          >
                            {item}
                          </li>
                        ))}
                    </ul>
                    <div
                      className={classNames(
                        section.img ? "max-w-xl" : "",
                        "pt-1 space-y-4"
                      )}
                    >
                      <p className="text-md leading-7 text-gray-600">
                        {section.text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {section.img && (
                <img
                  src={section.img}
                  alt=""
                  className={classNames(
                    "w-full max-w-xs rounded-3xl object-cover lg:max-w-xs xl:row-span-2 xl:row-end-2 m-4 shadow-md",
                    section.ratio || ""
                  )}
                />
              )}
            </div>
          </div>
        ))}
        <TestimonialsComponent testimonials={data.testimonials} />
      </div>
    </>
  );
}
