import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PublicApp } from "./PublicApp";

export const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="bg-ergo-50/30">
          <Routes>
            <Route path="/*" element={<PublicApp />} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
};
