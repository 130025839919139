import React from "react";
import { useState } from "react";

type Section = {
  title: string;
  text?: string;
  content?: string[];
};

const data: { sections: Section[] } = {
  sections: [
    {
      title: "Bei uns sind Sie richtig, wenn Sie...",
      content: [
        "eine individuell auf Sie und Ihre Bedürfnisse abgestimmte Ergotherapie suchen",
        "gemeinsam, individuell für ihren Alltag umsetzbare Lösungen erarbeiten wollen",
        "sich bewusst dazu entscheiden, etwas verändern zu wollen",
        "Zeiträume wieder sinnvoll auskosten wollen",
      ],
    },
  ],
};

export default function StartPage() {
  return (
    <div>
      <div>
        <div className="mx-auto max-w-7xl px-6 pt-12 sm:pt-24 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <div className="max-w-2xl lg:col-span-2 xl:col-auto">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-tight">
                Jeder Mensch ist individuell und genauso individuell ist jede Therapie.
              </h1>
            </div>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                Herzlich willkommen in unserer Praxis für <br />
                <span className="font-semibold text-gray-900">ERGOTHERAPIE UND ZEIT(T)RÄUME</span>
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Wir unterstützen, beraten, coachen und motivieren unsere Klienten:Innen in vielfältiger Weise.
              </p>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/images/tree.jpeg"}
              className="mt-10 w-full max-w-sm rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-sm xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
      </div>

      <div className="mt-12 overflow-hidden pt-6">
        <div className="mx-auto max-w-7xl gap-x-12 lg:flex lg:px-8">
          <p className="text-base leading-8 text-gray-600">
            Es ist uns sehr wichtig, dass die Therapie an Ihrem Alltag ansetzt, sodass gemeinsam erarbeitete Strategien
            zu Hause umgesetzt werden können. Wir setzen an Ihren aktuellen Bedürfnissen und Wünschen an und beziehen
            ihre bereits vorhandenen Ressourcen mit in die Therapie ein.
          </p>
          <p className="text-lbaseg leading-8 text-gray-600">
            Die ganzheitliche Betrachtungsweise unserer Klienten:Innen ist uns sehr wichtig. Deshalb werden nach
            Möglichkeit auch Angehörige bzw. enge Bezugspersonen mit in die Therapie integriert. Unsere
            Behandlungsschwerpunkte liegen in den Bereichen <span className="font-semibold">Neurologie</span> und{" "}
            <span className="font-semibold">Psychiatrie</span>.
          </p>
        </div>
        <div className="mx-auto max-w-7xl gap-x-12 lg:flex lg:px-8">
          <p className="mt-16 text-base leading-8 text-gray-600 border-l py-3 pl-8 border-l-2 border-ergo-500">
            Voraussetzung für eine ergotherapeutische Behandlung als <br />
            Kassenleistung ist eine Verordnung von ihrer behandelnden Ärztin oder ihrem Arzt. <br />
            <br />
            Zusätzlich bieten wir zahlreiche ergänzende und präventive Leistungen auf Selbstzahlerbasis an.
          </p>
        </div>
        <div className="mt-16">
          {data.sections.map((section, index) => (
            <div key={index} className="p-6 ">
              <h2 className="text-2xl font-bold text-gray-800 mb-6">{section.title}</h2>
              {section.text && <p className="text-md leading-7 text-gray-600">{section.text}</p>}
              {section.content && (
                <ul className="list-disc marker:text-ergo-300 list-inside space-y-1">
                  {section.content.map((item, index) => (
                    <li key={index} className="text-md leading-7 text-gray-600">
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="mt-16 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Weitere Angebote</h2>
            <p className="mt-6 text-base leading-7 text-gray-600 lg:pr-8">
              Neben der klassischen Ergotherapie bieten wir auch weitere Schulungen und Kurse an. Hier finden Sie eine
              Übersicht über die aktuellen Angebote.
            </p>
            <div className="mt-6 grid gap-6 sm:gap-8 lg:grid-cols-2">
              <div>
                <div className="sm:p-6">
                  <h3 className="text-xl font-semibold text-gray-900">Energiemanagement Schulung</h3>
                  <p className="mt-2 text-md text-gray-600">
                    Individuelles Erstgespräch, fünf Gruppenlektionen zu Themen wie Pausenmanagement und wirkungsvolle
                    Kommunikation, individuelles Einzelgespräch zum Thema "meine Ziele im Alltag", begleitendes
                    Selbsttraining für zu Hause
                  </p>
                  <p className="mt-4 text-md text-gray-600">
                    <b>Wer: </b>Klienten:Innen mit Fatigue, z.B. nach Post-Covid Erkrankung, Krebserkrankung,
                    Schlaganfall, Schädel-Hirn-Trauma, Morbus Parkinson, Multiple Sklerose, Narkolepsie
                  </p>
                  <p className="mt-4 text-md text-gray-600">
                    <b>Kosten: </b>Mögliche Kostenübernahme durch die Krankenkasse
                  </p>
                  <p className="mt-4 text-md text-gray-600">Angeboten von Sandra Meyer</p>
                </div>
              </div>
              <div>
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg font-semibold text-gray-900">Selbstwert-Kurs für Kinder</h3>
                  <p className="mt-2 text-md text-gray-600">
                    Stärkung des Selbstbewusstseins durch Wahrnehmung und Respektierung des eigenen Körpers und der
                    eigenen Grenzen, Übungen zum selbstbewussten Auftreten, Prävention gefährlicher Situationen,
                    Selbstverteidigungstechniken
                  </p>
                  <p className="mt-4 text-md text-gray-600">
                    <b>Wer: </b>Kinder im Alter von 5-8 Jahren
                  </p>
                  <p className="mt-4 text-md text-gray-600">
                    <b>Kosten: </b>80€ für acht Einheiten
                  </p>
                  <p className="mt-4 text-md text-gray-600">Angeboten von Charlotte Fröhlich</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
          <div className="grid gap-6 sm:gap-8 lg:grid-cols-2">
            <div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Praxis</h2>
              <p className="mt-6 text-base leading-7 text-gray-600 lg:pr-8">
                In unserer Praxis finden Sie einen gemütlich eingerichteten Besprechungsraum, einen extra reizarm
                gestalteten großen Raum mit Sprossenwand und Therapieliege sowie zahlreiche Materialien, Konzepte und
                Assessments zur Unterstützung Ihrer Bedürfnisse.
              </p>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/images/start_image_1.jpeg"}
              alt=""
              className="w-full rounded-2xl bg-gray-50 object-cover"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/start_image_6.jpg"}
              alt=""
              className="w-full rounded-2xl bg-gray-50 object-cover"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/start_image_3.jpeg"}
              alt=""
              className="w-full rounded-2xl bg-gray-50 object-cover"
            />
          </div>
        </div>
      </div>
      <div className="mt-24 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Folgen Sie uns auf Instagram
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              Bleiben Sie auf dem Laufenden über unsere Praxis und erhalten Sie wertvolle Tipps.
            </p>
            <div className="mt-8 flex justify-center outline-none">
              <a
                href="https://www.instagram.com/ergotherapie_rottweil"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-ergo-600 hover:bg-ergo-700 focus:outline-none focus:ring-0"
              >
                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
                @ergotherapie_rottweil
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
