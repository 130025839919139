import React, { useState } from "react";
import { sendEmail } from "../services/mail-service";
import { PATH_FAQ, PATH_PRIVACY_POLICY } from "../utils/constants";
import { Helmet } from "react-helmet-async";
import OfficeLocationComponent from "../components/common/OfficeLocationComponent";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");
  const [birthday, setBirthday] = useState("");
  const [hasPrescription, setHasPrescription] = useState(false);
  const [needsHomeVisit, setNeedsHomeVisit] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);

  /**
   * Handle form submission
   * @param e Form event
   */
  async function handleSubmit(e: React.FormEvent<HTMLButtonElement>) {
    setError(false);
    setSending(true);
    e.preventDefault();
    try {
      await sendEmail(
        name,
        reason,
        message,
        email,
        phone,
        birthday,
        hasPrescription,
        needsHomeVisit
      );
      setSending(false);
      setSuccess(true);
      setError(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setSending(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Kontakt | Lisa Spreitzer Ergotherapeutin</title>
        <meta
          name="description"
          content="Nehmen Sie Kontakt auf für ein kostenloses Vorgespräch mit Lisa Spreitzer, erfahrene Ergotherapeutin. Füllen Sie das Kontaktformular aus und Lisa wird sich so bald wie möglich bei Ihnen melden."
        />
      </Helmet>
      <div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Kontakt für Erstgespräch
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Bei Interesse an einem Termin füllen Sie bitte dieses
            Kontaktformular aus. In einem ca. 15-minütigen kostenlosen
            Vorgespräch klären wir telefonisch erste Fragen.
          </p>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Hier finden Sie gängige{" "}
            <a href={PATH_FAQ} className="text-ergo-600 hover:text-ergo-500">
              Fragen und Antworten
            </a>
            .
          </p>
        </div>
        {success ? (
          <p className="mt-6 text-ergo-500 text-center">
            Ihre Nachricht wurde erfolgreich versendet. Ich werde mich so bald
            wie möglich bei Ihnen melden.
          </p>
        ) : (
          <form>
            <div className="bg-ergo-500/10 rounded-2xl p-12 mx-auto mt-16 max-w-3xl sm:mt-20">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <label
                    htmlFor="name"
                    className="block text-sm font-semibold leading-6 text-ergo-600"
                  >
                    Name *
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required={true}
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:bg-ergo-50 focus:ring-inset focus:ring-ergo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-ergo-600"
                  >
                    Email *
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required={true}
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:bg-ergo-50 focus:ring-inset focus:ring-ergo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-semibold leading-6 text-ergo-600"
                  >
                    Telefonnummer
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:bg-ergo-50 focus:ring-inset focus:ring-ergo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="birthday"
                    className="block text-sm font-semibold leading-6 text-ergo-600"
                  >
                    Geburtstag
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setBirthday(e.target.value)}
                      value={birthday}
                      type="text"
                      name="birthday"
                      id="birthday"
                      autoComplete="bday"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:bg-ergo-50 focus:ring-inset focus:ring-ergo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="reason"
                    className="block text-sm font-semibold leading-6 text-ergo-600"
                  >
                    Grund
                  </label>
                  <div className="mt-2.5">
                    <input
                      onChange={(e) => setReason(e.target.value)}
                      value={reason}
                      type="text"
                      name="reason"
                      id="reason"
                      autoComplete="text"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:bg-ergo-50 focus:ring-inset focus:ring-ergo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-1">
                  <div className="mt-2.5 flex items-center outline-none">
                    <input
                      onChange={(e) => setHasPrescription(e.target.checked)}
                      checked={hasPrescription}
                      type="checkbox"
                      name="hasPrescription"
                      id="hasPrescription"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setHasPrescription(!hasPrescription);
                        }
                      }}
                      className="form-checkbox h-4 w-4 text-ergo-600 transition duration-150 ease-in-out focus:ring-0"
                    />
                    <label
                      htmlFor="hasPrescription"
                      className="ml-4 block text-sm font-semibold leading-6 text-ergo-600"
                    >
                      Rezept bereits vorhanden
                    </label>
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <div className="mt-2.5 flex items-center outline-none">
                    <input
                      onChange={(e) => setNeedsHomeVisit(e.target.checked)}
                      checked={needsHomeVisit}
                      type="checkbox"
                      name="needsHomeVisit"
                      id="needsHomeVisit"
                      className="form-checkbox h-4 w-4 text-ergo-600 transition duration-150 ease-in-out focus:ring-0"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setNeedsHomeVisit(!needsHomeVisit);
                        }
                      }}
                    />
                    <label
                      htmlFor="needsHomeVisit"
                      className="ml-4 block text-sm font-semibold leading-6 text-ergo-600"
                    >
                      Hausbesuch
                    </label>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="mt-3 block text-sm font-semibold leading-6 text-ergo-600"
                  >
                    Nachricht *
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      name="message"
                      id="message"
                      rows={4}
                      minLength={50}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:bg-ergo-50 focus:ring-inset focus:ring-ergo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              {error && (
                <p className="mt-6 text-red-500 text-center">
                  Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später
                  erneut.
                </p>
              )}
              <div className="mt-6">
                <p className="text-xs text-ergo-500">
                  Beim Senden einer Nachricht stimmen Sie der{" "}
                  <a
                    href={PATH_PRIVACY_POLICY}
                    className="text-ergo-600 hover:text-ergo-700"
                  >
                    Datenschutzerklärung
                  </a>{" "}
                  zu.
                </p>
                <button
                  disabled={sending}
                  onClick={(e) => handleSubmit(e)}
                  type="button"
                  className="mt-4 block rounded-md bg-ergo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-ergo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ergo-600"
                >
                  Nachricht senden
                </button>
              </div>{" "}
            </div>
          </form>
        )}
      </div>
    </>
  );
}
