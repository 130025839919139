import React from "react";

export default function ImpressumPage() {
  return (
    <>
      <div className="text-left">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Impressum
        </h1>
        <p className="mt-3 text-sm font-semibold text-gray-700 leading-6">
          ERGOTHERAPIE UND ZEIT(T)RÄUME <br />
          Lisa Spreitzer <br />
          Auf dem Wall 29 <br />
          78628 Rottweil
        </p>

        <p className="mt-3 text-sm font-semibold text-gray-700 leading-6">
          Telefon: 0741 89698293
          <br /> E-Mail: info@ergorw.de
        </p>
        <p className="mt-3 text-sm text-gray-700">
          Gesetz über den Beruf der Ergotherapeutin und des Ergotherapeuten
          <a
            className="text-ergo-500"
            href="https://www.gesetze-im-internet.de/bearbthg/BJNR012460976.html"
          >
            {" "}
            (Ergotherapeutengesetz - ErgThG)
          </a>
        </p>

        {/* Additional information */}
        <h2 className="mt-6 text-xl font-bold text-gray-900">
          Angaben gemäß § 5 TMG:
        </h2>
        <p className="mt-3 text-sm text-gray-700 leading-6">
          Berufsbezeichnung und berufsrechtliche Regelungen
          <br />
          Berufsbezeichnung: Staatlich aner­kann­te Ergotherapeutin
          <br />
          Zuständige Kammer: Regierungspräsidium Stuttgart
          <br />
          Verliehen durch: das Land Baden-Württemberg
          <br />
          Es gel­ten fol­gen­de berufs­recht­li­che Regelungen:
          Ergotherapeutengesetz- abgekürzt: ErgThG
        </p>

        {/* Additional information */}
        <h2 className="mt-6 text-xl font-bold text-gray-900">
          Angaben zur Berufshaftpflichtversicherung
        </h2>
        <p className="mt-3 text-sm text-gray-700 leading-6">
          Name und Sitz der Versicherung: Prokundo GmbH, Dortmund
        </p>

        <h2 className="mt-6 text-xl font-bold text-gray-900">
          Inhalt des Onlineangebotes
        </h2>
        <p className="mt-3 text-sm text-gray-700 leading-6">
          Die Autorin übernimmt keinerlei Gewähr für die Aktualität,
          Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
          Informationen. Haftungsansprüche gegen die Autorin, welche sich auf
          Schäden materieller oder ideeller Art beziehen, die durch die Nutzung
          oder Nichtnutzung der dargebotenen Informationen bzw. durch die
          Nutzung fehlerhafter und unvollständiger Informationen verursacht
          wurden, sind grundsätzlich ausgeschlossen, sofern seitens der Autorin
          kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden
          vorliegt. Alle Angebote sind unverbindlich. Die Autorin behält es sich
          ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder komplett einzustellen.
        </p>

        <h2 className="mt-6 text-xl font-bold text-gray-900">
          Verweise und Links
        </h2>
        <p className="mt-3 text-sm text-gray-700 leading-6">
          Bei direkten oder indirekten Verweisen auf fremde Webseiten
          (“Hyperlinks”), die außerhalb des Verantwortungsbereiches der Autorin
          liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
          Kraft treten, in dem die Autorin von den Inhalten Kenntnis hat und es
          ihr technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern. Die Autorin erklärt hiermit
          ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
          Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
          aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft
          der verlinkten / verknüpften Seiten hat die Autorin keinerlei
          Einfluss. Deshalb distanziert sie sich hiermit ausdrücklich von allen
          Inhalten aller verlinkten / verknüpften Seiten, die nach der
          Linksetzung verändert wurden. Diese Feststellung gilt für alle
          innerhalb des eigenen Internetangebotes gesetzten Links und Verweise
          sowie für Fremdeinträge in von der Autorin eingerichteten
          Gästebüchern, Diskussionsforen, Linkverzeichnissen und in allen
          anderen Formen von Datenbanken, auf deren Inhalt externe
          Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die aus der
          Nutzung oder Nichtnutzung solcherart dargebotener Informationen
          entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen
          wurde, nicht derjenige, der über Links auf die jeweilige
          Veröffentlichung lediglich verweist.
        </p>

        <h2 className="mt-6 text-xl font-bold text-gray-900">
          Urheber- und Leistungsschutzrechte
        </h2>
        <p className="mt-3 text-sm text-gray-700 leading-6">
          Die Autorin ist bestrebt, in allen Publikationen die Urheberrechte der
          verwendeten Grafiken, Tondokumente, Videosequenzen, Bilder und Texte
          zu beachten, von ihr selbst erstellte Grafiken, Tondokumente,
          Videosequenzen, Bilder und Texte zu nutzen oder auf lizenzfreie
          Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle
          innerhalb des Internetangebotes genannten und ggf. durch Dritte
          geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
          Bestimmungen des jeweils gültigen Kennzeichenrechts und den
          Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund
          der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen
          nicht durch Rechte Dritter geschützt sind! Das Copyright für
          veröffentlichte, von der Autorin selbst erstellte Objekte bleibt
          allein bei der Autorin der Seiten. Eine Vervielfältigung oder
          Verwendung solcher Grafiken, Tondokumente, Videosequenzen, Bilder und
          Texte in anderen elektronischen oder gedruckten Publikationen ist ohne
          ausdrückliche Zustimmung der Autorin nicht gestattet.
        </p>
        <p className="mt-3 text-sm text-gray-700 leading-6">
          Soweit die Inhalte auf dieser Seite nicht vom Anbieter erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Sollten Sie
          trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bittet
          der Anbieter um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen wird der Anbieter derartige Inhalte umgehend
          entfernen.
        </p>

        <p className="mt-3 text-sm text-gray-700">
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Lisa Spreitzer
        </p>
      </div>
    </>
  );
}
