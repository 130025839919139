import { classNames } from "../../../utils/utils";
import { QuoteSvg } from "../../svgs/QuoteSvg";
export type Testimonial = {
  quote: string;
  person?: string;
};

export type Testimonials = {
  testimonials: Testimonial[];
};

const Testomonial: React.FC<Testimonial> = ({ quote, person }) => (
  <div className="flex flex-col items-center justify-center p-6 rounded-lg shadow shadow-ergo-200 bg-ergo-500/5">
    <QuoteSvg classNames="w-16 h-16 rounded-full mx-auto rounded-full bg-ergo-200 fill-current text-ergo-600" />

    <blockquote className="mt-6 text-md text-center text-gray-600 italic">
      {quote}
    </blockquote>
    {person && (
      <p className="mt-4 text-xs text-center text-ergo-600 text-sm">{person}</p>
    )}
  </div>
);

export const TestimonialsComponent: React.FC<Testimonials> = ({
  testimonials,
}) => (
  <div className="mt-12 pb-6">
    {/* <h2 className="text-2xl font-bold text-gray-800 mb-6">Testimonials</h2> */}
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
      {testimonials.map((testimonial, index) => (
        <Testomonial key={index} {...testimonial} />
      ))}
    </div>
  </div>
);
