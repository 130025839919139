import React from "react";
import {
  Testimonial,
  TestimonialsComponent,
} from "../components/common/testimonial/TestiomonialComponent";
import { classNames } from "../utils/utils";
import { Helmet } from "react-helmet-async";

type Section = {
  title: string;
  content: string[];
  texts?: string[];
  img?: string;
  ratio?: string;
};

const data: { sections: Section[]; testimonials: Testimonial[] } = {
  sections: [
    {
      title: "Neurologie / Geriatrie",
      content: [
        "Neurologische Erkrankungen:",
        "Schlaganfälle/Blutungen",
        "neurologische Störungen bedingt durch Tumore oder Traumata",
        "Multiple Sklerose",
        "ALS",
        "Morbus Parkinson",
        "Demenz",
        "Kognitive Defizite",
        "Post-Covid",
      ],
      img: process.env.PUBLIC_URL + "/images/neurology.jpg",
      ratio: "aspect-[3/4]",
      texts: [
        "Nach einem Schicksalsschlag wie beispielsweise einem Schlaganfall ist nichts mehr wie es war. Ergotherapie setzt genau da an. Wir unterstützen und begleiten Sie, greifen auf , was Ihnen schwerfällt und erarbeiten gemeinsame Therapieziele. Mögliche Fragen könnten sein: Was ist Ihnen wichtig? Was möchten Sie im Alltag wieder  können? Welche Unterstützungsmöglichkeiten gibt es?",
        "Angehörige oder enge Bezugspersonen können mit in die Therapie einbezogen werden. Selbstverständlich wird dafür im Vorfeld das Einverständnis des Klienten:In eingeholt.",
      ],
    },
    {
      title: "Was beinhaltet eine sensomotorisch-perzeptive Behandlung?",
      content: [
        "Verbesserung der Grob- und Feinmotorik",
        "Verbesserung von alltäglichen Verrichtungen (z.B. Zähne putzen, anziehen etc.)",
        "Verbesserung der Sensomotorik (Tonusregulation, Anbahnen physiologischer Bewegungen, Koordination von Bewegungsabläufen)",
        "Erarbeiten der individuellen Grundmobilität",
        "Sensibilitätstraining",
        "Propriozeptive Neuromuskuläre Fazilitation bei Gesichtslähmung",
        "Graphomotorisches Training",
      ],
    },
    {
      title: "Was wird bei einem Hirnleistungstraining beübt?",
      content: [
        "Verbesserung der Konzentrations- und Aufmerksamkeitsfähigkeit",
        "Erarbeiten von Gedächtnisstrategien",
        "Einbeziehung von Angehörigen/nahen Bezugspersonen, um auf Hilfestellung/Einbindung im häuslichen Umfeld hinzuweisen",
        "Beratungen bezüglich Gefahren im Alltag, Hinweis auf unterstützende Organisationen",
        "Erhaltung und Verbesserung der Merkfähigkeit, Leistungen im auditiven, sowie im visuellen Bereich",
        "Schulung der Orientierungsfähigkeit (zeitl., örtl., persönl., situativ)",
      ],
    },
    {
      title: "Was beinhaltet ein ADL-Training?",
      content: [
        "Wasch- und Anziehtraining",
        "Nahrungsaufnahme",
        "Haushaltstraining",
        "Hilfsmittelberatung und Hilfsmittelversorgung",
      ],
    },
    {
      title: "Was noch berücksichtigt werden sollte?",
      content: [
        "Stärkung und Förderung des Selbstwertgefühls",
        "Verbesserung der sozio-emotionalen Fähigkeiten",
        "Stärkung im Bereich: Kommunikationsfähigkeiten",
        "Umgang innerhalb der Partnerschaft",
        "Umgang mit dem Thema Sexualität",
        "Kontinenz",
        "Angehörigenberatung",
      ],
    },
  ],
  testimonials: [
    {
      quote:
        "Dank der Ergotherapie bin ich wieder in der Lage, meine betroffene Hand besser zu spüren. Ich kann wieder ein Wasserglas halten und selbständig trinken.",
    },
    {
      quote:
        "In der Praxis habe ich wertvolle Tipps erhalten, um mit der Demenz meines Mannes besser umgehen zu können.Ich kann auch als Angehörige auftretende Probleme innerhalb der Therapie einbringen, damit  gemeinsam Lösungen erarbeitet werden können.",
    },
  ],
};

export default function NeurologieGariatriePage() {
  return (
    <>
      <Helmet>
        <title>Neurologie / Geriatrie | Ergotherapie</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über die ergotherapeutischen Behandlungen bei neurologischen Erkrankungen und in der Geriatrie. Hier finden Sie Informationen zu Behandlungen für Zustände wie Schlaganfall, Multiple Sklerose, ALS, Parkinson, Demenz und mehr. Auch die Sensomotorisch-Perzeptive Behandlung, Hirnleistungstraining und Alltagstraining (ADL) werden diskutiert."
        />
      </Helmet>
      <div>
        {data.sections.map((section, index) => (
          <div>
            <div className="items-center mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <div className="lg:mt-0 xl:col-end-1 xl:row-start-1">
                <div>
                  <div key={index} className="p-6 ">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6">
                      {section.title}
                    </h2>

                    <ul className="list-disc marker:text-ergo-300 list-inside space-y-1">
                      {section.content.map((item, index) => (
                        <li
                          key={index}
                          className="text-md leading-7 text-gray-600"
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                    <div
                      className={classNames(
                        section.img ? "max-w-xl" : "",
                        "pt-6 space-y-4"
                      )}
                    >
                      {section.texts?.map((item, index) => (
                        <p className="text-md leading-7 text-gray-600">
                          {item}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {section.img && (
                <img
                  src={section.img}
                  alt=""
                  className={classNames(
                    "w-full max-w-xs rounded-3xl object-cover lg:max-w-xs xl:row-span-2 xl:row-end-2 m-4 shadow-md",
                    section.ratio || ""
                  )}
                />
              )}
            </div>
          </div>
        ))}
        <TestimonialsComponent testimonials={data.testimonials} />
      </div>
    </>
  );
}
