import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { secrets } from "../../utils/secrets";
import {
  GoogleMapsProvider,
  useGoogleMap,
} from "@ubilabs/google-maps-react-hooks";

interface MuseumData {
  name: string;
  position: google.maps.LatLngLiteral;
}

const locations: MuseumData[] = [
  {
    name: "Ergotherapie Rottweil",
    position: { lat: 48.158268, lng: 8.6352986 },
  },
];

const OfficeLocationComponent: React.FC = ({}) => {
  const map = useGoogleMap();
  const [mapContainer, setMapContainer] = useState(null);
  const mapRef = useCallback((node: any) => {
    node && setMapContainer(node);
  }, []);

  const mapOptions = {
    center: { lat: 48.158268, lng: 8.6352986 },
    zoom: 15,
  };

  const MapMarkers: FunctionComponent<Record<string, unknown>> = () => {
    // Get the global map instance with the useGoogleMap hook
    const map = useGoogleMap();

    const [, setMarkers] = useState<Array<google.maps.Marker>>([]);

    // Add markers to the map
    useEffect(() => {
      if (!map) {
        return () => {};
      }

      const initialBounds = new google.maps.LatLngBounds();

      const museumMarkers: Array<google.maps.Marker> = locations.map(
        (museum) => {
          const { position, name } = museum;

          const markerOptions: google.maps.MarkerOptions = {
            map,
            position,
            title: name,
            clickable: false,
          };

          initialBounds.extend(position);

          return new google.maps.Marker(markerOptions);
        }
      );

      // Set the center of the map to fit markers
      map.setCenter(initialBounds.getCenter());

      setMarkers(museumMarkers);

      // Clean up markers
      return () => {
        museumMarkers.forEach((marker) => marker.setMap(null));
      };
    }, [map]);

    return null;
  };

  return (
    <div className="mt-24 rounded-lg ring-ergo-300 bg-ergo-500/10 block items-center sm:flex">
      <GoogleMapsProvider
        googleMapsAPIKey={secrets.mapsAPIKey}
        mapContainer={mapContainer}
        mapOptions={mapOptions}
      >
        <div
          className="rounded-lg"
          ref={mapRef}
          style={{ height: "400px", width: "100%" }}
        />
        <MapMarkers />
      </GoogleMapsProvider>
      <div className="font-normal text-sm text-ergo-500 p-8 sm:p-12 items-center">
        <p className="text-2xl text-ergo-600 leading-10">
          Ergotherapie Rottweil
        </p>
        <p className="mt-3 text-ergo-500">Lisa&nbsp;Spreitzer</p>
        <p className="mt-6 sm:mt-12 leading-6">
          Auf dem Wall 29 <br /> 78628 Rottweil <br />
          <br />
          Tel:&nbsp;0741/89698293 <br />
          Mail:&nbsp;info@ergorw.de
        </p>
      </div>
    </div>
  );
};

export default OfficeLocationComponent;
