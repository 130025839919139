import React from "react";
import { Helmet } from "react-helmet-async";
import { TestimonialsComponent } from "../components/common/testimonial/TestiomonialComponent";
import { classNames } from "../utils/utils";

type OfferSection = {
  title: string;
  content: string[];
  details?: {
    label: string;
    value: string;
  }[];
  texts?: string[];
  isMainSection?: boolean;
};

const data: { sections: OfferSection[] } = {
  sections: [
    {
      title: "THOP Elterntraining",
      content: [
        "Das eigene Kind besser verstehen lernen, wir nehmen die Probleme unter die Lupe,",
        "als Eltern/Familie gemeinsame Wege finden,",
        "die Stärken des Kindes sehen, gut geplant ist halb entspannt,",
        "Austausch untereinander",
      ],
      details: [
        {
          label: "Wer",
          value: "Eltern von Kindern im Grundschulalter (6-12 Jahre), bei Diagnose oder Verdacht auf ADHS",
        },
        {
          label: "Kosten",
          value: "Mögliche Kostenübernahme durch das Programm STÄRKE, acht Module á 120 Minuten",
        },
        {
          label: "Angeboten von",
          value: "Charlotte Fröhlich",
        },
      ],
      isMainSection: true,
    },
    {
      title: "Trauerbegleitung",
      content: [
        "Wir begleiten Sie als Angehörige/n/ oder Zugehörige/n, wenn ein lieber Mensch aus Ihrem persönlichen Umfeld im Sterben liegt oder verstorben ist.",
        "Die Trauerbegleitung findet im Einzelsetting und innerhalb der Gruppe statt.",
        "Dabei werden die Phasen der Trauer und ihre Verdrängung, die eigene Stärkung und der individuelle Umgang mit der Trauer bearbeitet.",
        "Unterstützt werden wir von einer langjährigen Fachexpertin aus dem palliativ-medizinischen Bereich.",
      ],
      details: [
        {
          label: "Wer",
          value:
            "Angehörige, die sich in Trauer befinden, wenn ein lieber Mensch im Sterben liegt oder verstorben ist (4-6 Teilnehmer:Innen)",
        },
        {
          label: "Wann",
          value: "einmal wöchentlich",
        },
        {
          label: "Umfang",
          value: "10 Einheiten: psychisch- funktionelle Behandlung",
        },
      ],
      isMainSection: true,
    },
    {
      title: "Handtherapie",
      content: [
        "Rheumatische Erkrankungen (z.B. Arthritis)",
        "Verletzungen der Sehnen, Bänder, Gelenkkapseln und Knochen",
        "Karpaltunnelsyndrom, M. Duypuytren",
        "Morbus Sudeck",
        "Vor- und Nachbehandlung von Operationen",
        "Postoperativen Narben",
      ],
      texts: [
        "Eine Handtherapie kommt dann zum Tragen, wenn die Funktionen der Hand durch eine Schädigung in ihrer Funktionalität beeinträchtigt ist. Durch kleinste Verletzungen der Hand, kann es bereits zu gravierenden Einschränkungen im Alltag und oder Berufsleben kommen. Je nach Art und Verletzung werden unterschiedliche ergotherapeutische Maßnahmen ergriffen, um die Funktionalität der Hand wieder herzustellen und/oder zu verbessern.",
      ],
      isMainSection: true,
    },
    {
      title: "Was beinhaltet eine motorisch- funktionelle Behandlung?",
      content: [
        "Narbenbehandlung (professionelle Nachsorge durch entsprechende Pflege und Massage der Narbe)",
        "Verbesserung der Feinmotorik und der manuellen Geschicklichkeit",
        "Schmerzreduktion",
        "Wiederaufnahme von Alltagstätigkeiten oder das Erlernen von Kompensationsstrategien",
        "Erlernen von Gelenkschutzmaßnahmen zur Reduzierung von schmerzbedingten Reaktionen",
        "Verbesserung der Sensibilität",
        "Verbesserung der Beweglichkeit, der Muskelkraft, der Koordination, der Ausdauer und der Belastbarkeit",
        "Thermische Anwendungen (Wärme/Kälte) z.B. Vorbereitung und Unterstützung von aktiver und/oder passiver Mobilisation",
        "Taping = funktionelle Verbandstechnik, Gelenke können dadurch stabilisiert werden und vor weiteren Traumatisierungen geschützt werden.",
      ],
    },
    {
      title: "Was noch berücksichtigt werden sollte?",
      content: [
        "Psychische Stabilisierung durch begleitende Gespräche (z.B. zur Krankheitsverarbeitung)",
        "Nach Möglichkeit: Übungen für ein Eigentraining zu Hause",
        "Eventuell Einbeziehung von Angehörigen",
        "ggf. Arbeitsplatzgestaltung zur Schmerzreduzierung",
      ],
    },
  ],
};

const testimonials = [
  {
    quote:
      "Mein Morbus Sudeck hat mein Leben ganz schön auf den Kopf gestellt. Dank der Ergotherapie kann ich meine Hand wieder im Alltag einsetzen. Auch die Gespräche über meine Ängste und Sorgen haben mir sehr gut getan.",
  },
];

export default function OtherOffersPage() {
  return (
    <>
      <Helmet>
        <title>Zusatzangebote | Ergotherapie</title>
        <meta
          name="description"
          content="Entdecken Sie unsere Zusatzangebote: THOP Elterntraining, Trauerbegleitung und spezialisierte Handtherapie. Wir bieten individuelle Unterstützung für verschiedene Lebenssituationen und Bedürfnisse."
        />
      </Helmet>
      <div>
        {data.sections.map((section, index) => (
          <div>
            <div className="items-center mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <div className="lg:mt-0 xl:col-end-1 xl:row-start-1">
                <div>
                  <div key={index} className="p-6 ">
                    <h2 className={`${section.isMainSection ? "text-3xl" : "text-xl"} font-bold text-gray-800 mb-6`}>
                      {section.title}
                    </h2>
                    <ul className="list-disc marker:text-ergo-300 list-inside space-y-1">
                      {section.content.map((item, index) => (
                        <li key={index} className="text-md leading-7 text-gray-600">
                          {item}
                        </li>
                      ))}
                    </ul>
                    <div className={classNames(section.details ? "max-w-3xl" : "", "pt-6 space-y-4")}>
                      {section.texts?.map((item, index) => (
                        <p key={index} className="text-md leading-7 text-gray-600">
                          {item}
                        </p>
                      ))}
                      {section.details && (
                        <div className="bg-ergo-50 p-4 rounded-lg">
                          {section.details.map((detail, idx) => (
                            <p key={idx} className="mb-2">
                              <span className="font-semibold">{detail.label}:</span> {detail.value}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <TestimonialsComponent testimonials={testimonials} />
      </div>
    </>
  );
}
